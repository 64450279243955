import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import Logout from "../../admin/logout/logout";
import { GetUser } from "../../common/getUser";
import {
  AiFillEdit,
  AiFillLock,
  AiOutlineDashboard,
  AiOutlineGlobal,
  AiOutlineImport,
  AiOutlineLogin,
  AiOutlineProfile,
  AiOutlineUser,
  AiOutlineSetting,
} from "react-icons/ai";

import logo from "../../../assets/img/logo.png";

import moment from "moment";
const { REACT_APP_WEBSITE_URL, REACT_APP_BASE_URL } = process.env;

const auth = () => {
  var token = null;

  var token = Cookies.get("AuthToken");

  if (token) {
  } else {
    sessionStorage.removeItem("appLoginSession");
    localStorage.removeItem("appLocalUserToken");
  }

  if (sessionStorage.getItem("appLoginSession")) {
    return true;
  } else if (localStorage.getItem("appLocalUserToken")) {
    var sessionDate = JSON.parse(localStorage.getItem("appLocalUserToken")).date;
    var diff = moment().startOf("day").diff(moment(sessionDate).startOf("day"), "days");
    if (diff >= 0 && diff < 1) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

// console.log("usersInfo in settings ", usersInfo)

const isAuthenticated = auth();

const Header = () => {
  const [user, setUser] = useState([]);
  const [isActive, setIsActive] = useState(false)
  const loginUser = GetUser();
  let location = useLocation();
  // console.log("location", location.pathname)
  let storedTokensList = JSON.parse(localStorage.getItem("storedTokens"));

  if (loginUser && loginUser.first_name && loginUser.last_name) {
    var initial = loginUser.first_name[0] + loginUser.last_name[0];
  } else {
    var initial = "";
  }


  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (isAuthenticated) {
      setUser(GetUser());
    }
  }, []);

  return (
    <>
      <header className="inner_header">
        <nav className="navbar navbar-expand-md bg-white border-bottom navbar-light">
          <div className="container-fluid">
            <Link to='/'>
              <img src={logo} alt="logo" className="nav-logo" />
            </Link>
            {/* <a href={`${REACT_APP_WEBSITE_URL}`} target={"_blank"}>
              <img src={logo} alt="logo" className="nav-logo" />
            </a> */}
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto">
                {/* }
                <li className="nav-item">
                  <NavLink to="/CreateSurvey" activeclassname="active">
                    <AiFillEdit /> Create Survey
                  </NavLink>
                </li> 

                {/* <li className="nav-item">
                  <NavLink to={{ pathname: `${REACT_APP_WEBSITE_URL}` }} target={"_blank"}>
                    <AiOutlineGlobal /> OpenEyes Surveys
                  </NavLink>
                </li> */}

                {isAuthenticated ? (
                  <>
                    <li className="nav-item">
                      <NavLink to="/MySurvey">
                        <AiOutlineProfile /> My Ongoing Surveys
                      </NavLink>
                    </li>

                    {user.register_as == "1" ? (
                      <>
                        <li className="nav-item">
                          <NavLink to="/SurveyList">
                            <AiOutlineProfile />  Manage Surveys
                          </NavLink>
                        </li>
                        {/* <li className="nav-item">
                          <NavLink to="/ManageUsers">
                            <AiOutlineProfile />Mangage Users
                          </NavLink>
                        </li> */}
                        
                        <li className="nav-item">
                          <NavLink to="/Users">
                          <AiOutlineSetting />  Admin Settings
                          </NavLink>
                        </li>
                        
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>

                    {location.pathname == "/Login" ? (
                      <li className="nav-item">
                        <NavLink activenameclass="active" style={{ display: "none" }} to="/Login">
                          <AiOutlineLogin /> Login
                        </NavLink>
                      </li>
                    ) : (
                      <li className="nav-item">
                        <NavLink activenameclass="active" to="/Login">
                          <AiOutlineLogin /> Login
                        </NavLink>
                      </li>
                    )}
                    {location.pathname == "/Registration" ? (
                      <li className="nav-item">
                        <NavLink activenameclass="active" style={{ display: "none" }} to="/Registration">
                          <AiFillLock /> Register
                        </NavLink>
                      </li>
                    ) :
                      (
                        <li className="nav-item">
                          <NavLink activenameclass="active" to="/Registration">
                            <AiFillLock /> Register
                          </NavLink>
                        </li>
                      )}
                    {/* <li className="nav-item">
                      <NavLink exact activenameclass="active" to="/Registration">
                        <AiFillLock /> Register
                      </NavLink>
                    </li> */}
                    {storedTokensList != null ? (<>
                      {location.pathname == "/MySurvey" ? (
                        <li className="nav-item">
                          <NavLink activenameclass="active" style={{ display: "none" }} to="/MySurvey">
                            <AiOutlineProfile /> My Ongoing Surveys
                          </NavLink>
                        </li>
                      ) : (
                        <li className="nav-item">
                          <NavLink activenameclass="active" to="/MySurvey">
                            <AiOutlineProfile /> My Ongoing Surveys
                          </NavLink>
                        </li>
                      )}

                    </>) : (<></>)}


                  </>
                )}
              </ul>
            </div>
          </div>
          {isAuthenticated ? (
            <>
              <div className="dropdown user-dropdown">
                <span className="dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <div className="circle-av shadow-sm">{initial}</div>
                </span>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink className="dropdown-item" to="/Settings" activeclassname="active">
                      <AiOutlineUser /> My Account
                    </NavLink>
                  </li>
                  {/* <li>
                <a className="dropdown-item" href="#">
                  <AiOutlineUnorderedList /> Organization List
                </a>
              </li> */}
                  <li>
                    <Logout setLoading={setLoading} />
                  </li>
                </ul>
              </div>
            </>
          ) : (
            <></>
          )}
        </nav>
      </header>
    </>
  );
};

export default Header;
